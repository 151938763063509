<template>
    <qrcode-vue class="qrcode" :value="value" :size="size" level="H" />
</template>

<script>
    import QrcodeVue from "qrcode.vue"
    export default {
        components: {
            QrcodeVue
        },
        props: {
            value: String,
            size: {
                type: [Number,String],
                default: 100
            }
        },
        data() {
            return {
                
            }
        }
    }
</script>

<style lang="scss" scoped>
    
</style>
